export default [{
  path: '/paineis-gerenciais',
  component: () => import('@/views/Index'),
  children: [
    {
      name: 'Painéis Gerenciais',
      path: '',
      component: () => import('@/views/paineisGerenciais/Index.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Painéis Gerenciais' , to: '/paineis-gerenciais', exact: false }
        ])
      }
    },
    {
      name: 'Dashboard LNO',
      path: 'dashboard-lno',
      component: () => import('@/views/paineisGerenciais/dashboardLno/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Painéis Gerenciais' , to: '/paineis-gerenciais', exact: true },
          { text: 'Dashboard LNO' , to: '/paineis-gerenciais/dashboard-lno', exact: false }
        ])
      }
    },
    {
      name: 'Dashboard de Serviços',
      path: 'dashboard-servicos',
      component: () => import('@/views/paineisGerenciais/dashboardServicos/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Painéis Gerenciais' , to: '/paineis-gerenciais', exact: true },
          { text: 'Dashboard de Serviços' , to: '/paineis-gerenciais/dashboard-servicos', exact: false }
        ])
      }
    },
    {
      name: 'Dashboard de Faturamento',
      path: 'dashboard-faturamento',
      component: () => import('@/views/paineisGerenciais/dashboardFaturamento/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Painéis Gerenciais' , to: '/paineis-gerenciais', exact: true },
          { text: 'Dashboard de Faturamento' , to: '/paineis-gerenciais/dashboard-faturamento', exact: false }
        ])
      }
    },
    {
      name: 'Painel Brasil',
      path: 'painel-brasil',
      component: () => import('@/views/paineisGerenciais/dadosBrasil/Index.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: (route) => ([
          { text: 'Painéis Gerenciais' , to: '/paineis-gerenciais', exact: true },
          { text: 'Painel Brasil' , to: '/paineis-gerenciais/painel-brasil', exact: false }
        ])
      }
    }
  ]
}]