import { onlyAdminUsers } from '@/router/guards'
import relatorios from './relatorios'
import users from './users'
import relatoriosCriados from './relatorios-criados'
import importacoesDados from './importacoes-dados'
import versionamentoBases from './versionamento-bases'
import importacoesIndger from './importacoes-indger'
import governanca from './governanca'

let children = [
  {
    name: 'Administracao',
    path: '',
    component: () => import('@/views/administracao/Index.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: (route) => ([
        { text: 'Administração', exact: false }
      ])
    },
    beforeEnter: onlyAdminUsers
  }
]

children = children.concat(
  relatorios,
  relatoriosCriados,
  importacoesDados,
  users,
  versionamentoBases,
  importacoesIndger,
  governanca
)

export default [{
  path: '/administracao',
  component: () => import('@/views/Index'),
  children
}]