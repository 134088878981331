const initialState = {
  id: null,
  name: null,
  logo: null,
  acronym: null
}

const state = {
  selectedCompany: initialState
}

const getters = {
  getSelectedCompany: state => state.selectedCompany,
  getCompanyId: state => state.selectedCompany.id,
  getCompanyName: state => state.selectedCompany.name,
  getCompanyLogo: state => state.selectedCompany.logo,
  getCompanyAcronym: state => state.selectedCompany.acronym,
}

const actions = {
  setSelectedCompany({ commit }, value) {
    commit('SET_SELECTED_COMPANY', value)
  },
  resetSelectedCompany({ commit }, value) {
    commit('SET_SELECTED_COMPANY', initialState)
  }
}

const mutations = {
  SET_SELECTED_COMPANY: (state, value) => (state.selectedCompany = value),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
